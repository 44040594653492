// import { useRef, useState, useEffect } from "react";
import { useRef, useContext } from "react";
import "./Footer.css";
import { LocationContext } from "../../App";

const Footer = () => {
    // const footerSpacer = useRef(null);
    const standardFooter = useRef(null);
    const {languageBook} = useContext(LocationContext);

    // const [footerSpace, setFooterSpace] = useState(0);

    // useEffect( () => {
    //     const setFooterSpacerHeight = () => {
    //         let footerHeight = standardFooter.current.clientHeight;
    //         setFooterSpace(footerHeight);
    //     }
    //     setFooterSpacerHeight();
    //     window.addEventListener("resize", setFooterSpacerHeight());
    // }, []);

    return(
        <>
            {/* <div id="footer-spacer" ref={footerSpacer} style={{height: footerSpace}}></div> */}
            <footer id="standard-footer" ref={standardFooter}>
                <div id="footer-div" className="reading-sec">
                    <h2>{languageBook.footerTitle}</h2>
                    <p>{languageBook.footerDesc}</p>
                </div>
            </footer>
        </>
        
    );
}

export default Footer;