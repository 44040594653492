const JsScrollAnim = () => {

    const observer = new IntersectionObserver( (entries) => {
        entries.forEach( entry => {
            if( entry.isIntersecting){
                entry.target.classList.add( "in-view" );
            } else {
                entry.target.classList.remove( "in-view" );
            }
            // entry.class
        })
        
    }, {
        root: null,
        rootMargin: "-48px -24px -24px -24px",
        threshold: 0.5
    })

    const elements = document.querySelectorAll( ".js-scroll-anim" );
    elements.forEach( element => {
        observer.observe( element );
    })
}

export default JsScrollAnim;