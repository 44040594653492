import './App.css';
import { Route, Routes } from 'react-router-dom';
import { createContext, useEffect, useState} from 'react';
import LocationOBJ from './page-components/components/LocationOBJ';
import Home from "./page-components/Home";
import AboutUs from './page-components/AboutUs';
import Futures from './page-components/Futures';
import Stock from './page-components/Stock';
import ApplyNow from './page-components/ApplyNow';
import AntiScam from './page-components/AntiScam';
import Error404 from './page-components/Error404';

import langZhHant from "./languages/zh-Hant.json";
import langEn from "./languages/en.json";

export const LocationContext = createContext();

function App() {
  const taiwan = new LocationOBJ("taiwan", "台灣", 318);
  const japan = new LocationOBJ("japan", "日本", 290);
  const us = new LocationOBJ("us", "U.S.", 150);
  const allLocations = {
      taiwan: taiwan,
      japan: japan,
      us: us,
  };
  const [currentLocation, setCurrentLocation] = useState(taiwan);

  const [languageBook, setLanguageBook] = useState(langZhHant);

  useEffect(() => {
    const location = currentLocation.id;
    if(location === "us") {
      setLanguageBook(langEn);
    } else {
      setLanguageBook(langZhHant);
    }
  }, [currentLocation])


  return (
    <LocationContext.Provider value={{allLocations, currentLocation, setCurrentLocation, languageBook}}>
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/futures-teaching' element={<Futures />} />
        <Route path='/stock-teaching' element={<Stock />} />
        <Route path='/apply-now' element={<ApplyNow />} />
        <Route path='/anti-scam' element={<AntiScam />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </LocationContext.Provider>
    
    
  );
}

export default App;
