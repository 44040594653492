import ArrowBTN from "./ArrowBTN";
import { useState, useEffect, useContext } from "react";
import { LocationContext } from "../../App";

const ArrowBTNs = () => {
    const [wrapper, setWrapper] = useState([]);

    const [platformFocus, setPlatformFocus] = useState(0);
    const {languageBook} = useContext(LocationContext);

    useEffect(() => {
        let virtualWrapper = [];
        const btnArray =[
            {plateform: `${languageBook.futuresPlatform}`, url: "/futures-teaching"}, 
            {plateform: `${languageBook.stockPlatform}`, url: "/stock-teaching"},
        ];

        const handleHover = (e) => {
            setPlatformFocus(e.target.getAttribute("index"));
        }
        
        btnArray.forEach((btnItem, index) => {
            if(index === 0){
                virtualWrapper.push( <ArrowBTN buttonText={btnItem.plateform} url={btnItem.url} key={index} index={index} onHover={handleHover} addClass={"arrow-btn-focus"} /> );
            }else {
                virtualWrapper.push( <ArrowBTN buttonText={btnItem.plateform} url={btnItem.url} key={index} index={index} onHover={handleHover} addClass={""} /> );
            }
            
        });
        setWrapper(virtualWrapper);
    }, [languageBook]);

    useEffect(() => {
        let allBTNs = document.querySelectorAll(".arrow-btn");
        allBTNs.forEach((btn, index) => {
            btn.classList.remove("arrow-btn-focus");
        })
        if(allBTNs.length >= 1) {
            allBTNs[platformFocus].classList.add("arrow-btn-focus");
        }
    }, [platformFocus]);

    return(
        <div id="cta-bottom">
            {wrapper}
        </div>
    )
}

export default ArrowBTNs;