import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import HeaderNav from "./HeaderNav";
import LocationSelector from "./LocationSelector";
import FinacialProducts from "./FinacialProducts";
import JsScrollAnim from "./JsScrollAnim"
import "./Header.css";
import { LocationContext } from "../../App";

const Header = () => {
    const [headerSpace, setHeaderSpace] = useState(0);
    const [headerMenuOpen, setHeaderMenuOpen] = useState(false);
    const headerSpacerRef = useRef(null);
    const navigate = useNavigate();
    const {languageBook} = useContext(LocationContext)

    useEffect( () => {
        const setHeaderSpacerHeight = () => {
            if (headerSpacerRef.current) {
                const header = document.querySelector("header");
                const height = header.clientHeight;
                const style = window.getComputedStyle(header);
                const marginTop = parseFloat(style.marginTop);
                const marginBottom = parseFloat(style.marginBottom);
                setHeaderSpace(height + marginTop + marginBottom);
            }
        }

        setHeaderSpacerHeight();
        window.addEventListener("resize", setHeaderSpacerHeight);
    },[])

    const location = useLocation();
    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }else{
            window.scrollTo(0, 0);
        }

        JsScrollAnim();
      }, [location]);
    

    return(
        <>
            <header>
                <FinacialProducts />
                <div className="stander-header" id="header">
                    
                    <div onClick={() => {
                        navigate("/");
                        setHeaderMenuOpen(false);
                    }} id="brand-wrapper" className="link-wrapper pointer">
                        <img className="header-logo" src="/logo/un077-logo-white-light.svg" alt="un077-logo" />
                        {/* <img className="header-logo logo-glow" src="/logo/un077-logo-white.svg" alt="un077-logo" /> */}
                        <p id="brand-name">{languageBook.siteTitle}</p>
                    </div>
                    <HeaderNav headerMenuOpen={headerMenuOpen} setHeaderMenuOpen={setHeaderMenuOpen} />
                    <div id="anti-scam-link" onClick={() => {
                        navigate("/anti-scam");
                        setHeaderMenuOpen(false);
                    }}>
                        <p>{languageBook.antiScheme}</p>
                    </div>
                    <div id="home-link" onClick={() => {
                        navigate("/");
                        setHeaderMenuOpen(false);
                    }}>
                        <p>{languageBook.aboutUs}</p>    
                    </div>  
                    <div id="scta" onClick={() => {
                        navigate("/#line-sec");
                        setHeaderMenuOpen(false);
                    }}>
                        <p>{languageBook.contactUs}</p>    
                    </div>            
                    <button id="apply-now" onClick={() => {
                        navigate("/apply-now");
                        setHeaderMenuOpen(false);
                    }}>{languageBook.applyNow}</button>
                    <LocationSelector setHeaderMenuOpen={setHeaderMenuOpen} />
                    <div id="backdrop">
                        
                    </div>
                    
                </div>
            </header>
            
            <div id="header-spacer"
                 ref={headerSpacerRef}
                 style={{height: headerSpace}}
            ></div>
        </>
        
        
    );
}
export default Header;