import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState, useContext } from "react";
import "./HeaderNav.css";
import { LocationContext } from "../../App";

const HeaderNav = ({headerMenuOpen, setHeaderMenuOpen}) => {
    const menu = useRef(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const {languageBook} = useContext(LocationContext);

    const handleMenuClick = () => {
        if(menu.current){
            setMenuOpen(!menuOpen);
            setHeaderMenuOpen(!menuOpen);
        }
    }
    useEffect(() => {
        const links = ["#nav-item-1", "#nav-item-2", "#home-link", "#anti-scam-link", "#scta", "#apply-now", "#backdrop", "#selector-container"];
        if(menuOpen){
            links.forEach((link) => {
                document.querySelector(link).classList.add("displayFlex");
            })
        }else{
            links.forEach((link) => {
                document.querySelector(link).classList.remove("displayFlex");
            })
        }
    }, [menuOpen])

    useEffect(() => {
        const menuCurrent = menu.current;
        if(menuCurrent){
            menuCurrent.addEventListener("click", handleMenuClick);
        }
        return() => {
            if(menuCurrent){
                menuCurrent.removeEventListener("click", handleMenuClick);
            }
        }
    })

    useEffect(() => {
        headerMenuOpen ? setMenuOpen(true) : setMenuOpen(false);
    }, [headerMenuOpen])

    const closeMenu = () => {
        setMenuOpen(false);
        setHeaderMenuOpen(false);
    }

    return(
        <ul className="header-nav">
            {/* <NavItem itemName="關於我們" url="/about-us" /> */}
            <NavItem id="nav-item-1" itemName={languageBook.futuresPlatform} url="/futures-teaching" onClick={closeMenu} />
            <NavItem id="nav-item-2" itemName={languageBook.stockPlatform} url="/stock-teaching" onClick={closeMenu} />
            <img id="menu" ref={menu} src="pictures/menu_24dp_E8EAED_FILL0_wght300_GRAD0_opsz24.svg" alt="menu" />
        </ul>
        
    );
}

const NavItem = ({id, itemName, url, onClick}) => {
    const navigate = useNavigate();
    return(
        <li onClick={() => {
            navigate(`${url}`);
            onClick();
        } } id={id} className=" nav-item no-style pointer">
            <p className="nav-text">{itemName}</p>
        </li>
    );
}

export default HeaderNav;