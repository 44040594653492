import Header from "./components/Header";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import "./AntiScam.css";
import { useContext } from "react";
import { LocationContext } from "../App";

const AntiScam = () => {
    const {languageBook} = useContext(LocationContext);

    return(
        <>
            <Helmet>
                <title>聯合資訊 | 反詐騙專區</title>
                <meta name="description" content="近來台股交易熱絡，投資詐騙訊息的數量也隨之飆升，提醒您進行投資理財前務必停看聽，注意風險。" />
                <meta name="keywords" content="聯合資訊, 期貨平台, 股票平台, 反詐騙"></meta>
                <meta property="og:title" content="聯合資訊 | 反詐騙專區" />
                <meta property="og:image" content="/pictures/un077-logo-white-light-square.png" />
                <meta property="og:description" content="近來台股交易熱絡，投資詐騙訊息的數量也隨之飆升，提醒您進行投資理財前務必停看聽，注意風險。" />
            </Helmet>
            <Header />
            <main>
                <section className="reading-sec">
                    <h1 id="anti-scam-h1" className="text-center">{languageBook.antiScheme}</h1>
                    <h2>{languageBook.antiFraudRemind}</h2>
                    <p>
                        {languageBook.antiFraudDesc}
                    </p>
                </section>
                <section className="reading-sec">
                    <a href="https://www.youtube.com/watch?v=Ws1Ym3boJLU" target="_blank" rel="noopener noreferrer">反詐騙宣導影片</a>
                    <div >
                        <iframe id="anti-scam-iframe" title="futures-video" type="text/html" src="https://www.youtube.com/embed/Ws1Ym3boJLU?version=3&loop=1&playlist=Ws1Ym3boJLU&autoplay=1&rel=0&mute=1&origin=http://example.com"></iframe>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}

export default AntiScam;