import { useContext, useEffect, useRef } from "react";
import * as THREE from "three";
import { LocationContext } from '../../App';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
// import { DragControls } from 'three/addons/controls/DragControls.js';
import { gsap } from "gsap/gsap-core";
import "./EarthDot.css";

const EarthToon = () => {
    const wrapper = useRef(null);
    const {currentLocation} = useContext(LocationContext);
    const initLocation = useRef( currentLocation );

    // new location events
    const taiwanSelect = new CustomEvent("locationSelected", {detail: {location: "taiwan"}});
    const japanSelect = new CustomEvent("locationSelected", {detail: {location: "japan"}});
    const usSelect = new CustomEvent("locationSelected", {detail: {location: "us"}});

    // get current location
    useEffect(() => {
        dispatchLocation( currentLocation );
    },)

    const dispatchLocation = (currentLocation) => {
        const locationID = currentLocation.id;

        if( locationID === "taiwan") {
            document.dispatchEvent( taiwanSelect );
        } else if ( locationID === "japan" ) {
            document.dispatchEvent( japanSelect );
        } else if ( locationID === "us" ) {
            document.dispatchEvent( usSelect );
        }
    }



    // add renderer to dom
    useEffect(() => {
        const wrapperChild = wrapper.current.children;

        if( wrapperChild.length > 0) {
            Array.from(wrapperChild).forEach( child => {
                child.remove();
            })
        }

        EarthThreejs( initLocation.current );
    }, [])

    return(
        <div id="earth-dot-wrapper" ref={wrapper}></div>
    )
}

export default EarthToon;


////////////////////////////
// Three js
const EarthThreejs = ( initLocation ) => {

    // wrapper
    const wrapper = document.querySelector("#earth-dot-wrapper");

    // new renderer
    const renderer = new THREE.WebGLRenderer({
        alpha: true,
        antialias: true,
    });
    renderer.shadowMap.enabled = true;
    let width = window.innerWidth * 2 / 3 ;
    if(width < 500){
        width = 500;
    }
    renderer.setSize( width , width );

    wrapper.appendChild( renderer.domElement );

    // scene
    const scene = new THREE.Scene();

    // fog
    scene.fog = new THREE.Fog(0xFFFFFF, 380, 500);

    // camera
    const camera = new THREE.PerspectiveCamera(
        45, // view angle
        600 / 600, // aspect ratio
        0.1, // near crop
        1000 // far crop
    );
    camera.position.set( 0, 200, 400);
    camera.lookAt( 0, 0, 0 );

    ////////////////////////////
    // light
    const ambientLight = new THREE.AmbientLight( 0xffFFFF, 4 );
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight( 0xffffff, 0 );
    directionalLight.position.set(0, 400, 0);
    directionalLight.castShadow = true;
    scene.add( directionalLight );

    // axesHelper
    // const axesHelper = new THREE.AxesHelper(5);
    // scene.add( axesHelper );

    // pivot
    const earthPivot = new THREE.Object3D();
    earthPivot.rotation.z = THREE.MathUtils.degToRad(-23.5);
    earthPivot.rotation.x = THREE.MathUtils.degToRad(20);
    scene.add(earthPivot);

    //////////////////////////////
    // cursor activities
    const cursor = new THREE.Vector2();
    window.addEventListener( "mousemove", (e) => {
        cursor.x = e.clientX;
        cursor.y = e.clientY;
    })

    // gltf loader
    let model;
    const earthGroup = new THREE.Group();
    const loader = new GLTFLoader();
    loader.load( "/3d/earth-toon-forWeb.gltf", (gltf) => {
        model = gltf.scene;
        earthGroup.add( model );

        if( initLocation.id === "taiwan" ) {
            model.rotation.y = -0.45 + orbit.getAzimuthalAngle() - 0.1;
        } else if( initLocation.id === "japan" ) {
            model.rotation.y = -0.65 + orbit.getAzimuthalAngle() - 0.1;
        } else if( initLocation.id === "us" ) {
            model.rotation.y = 3.5 + orbit.getAzimuthalAngle() - 0.1;
        }

        targetHighlight( initLocation.id );

        earthPivot.add( model );
        scene.add( model );

        

    }, undefined, ( error ) => {
        console.error( error );
    })
    // set camera angle
    const cameraPivot = new THREE.Object3D();
    cameraPivot.add(camera);
    scene.add(cameraPivot);
    

    // location event
    document.addEventListener( "locationSelected", (e) => { 

        if(camera && model) {
            
            model.rotation.y = model.rotation.y % (Math.PI*2);
            let destination;
            if( e.detail.location === "taiwan" ) {
                destination = -0.45;
            } else if( e.detail.location === "japan" ) {
                destination = -0.65;
            } else if( e.detail.location === "us" ) {
                destination = 3.5;
            }
            gsap.fromTo( model.rotation, 
                {y: model.rotation.y},
                {y: destination + orbit.getAzimuthalAngle() - 0.1, duration: 1, ease: "power2.out"}
            )

            targetHighlight( e.detail.location );  
        }
    })

    const targetHighlight = ( targetName ) => {

        model.traverse( (child) => {
            if( child.isMesh && child.name === "Sphere" ) {
                // child.material = new THREE.MeshBasicMaterial( {
                //     color: 0xAABBCC,
                // })
                child.material.color.set(0xadd3eb);
                child.material.metalness = 0.1;
                child.material.roughness = 0.8;
                child.scale.set(1 ,1, 1);
            } else if ( child.name === "taiwan" || child.name === "japan" || child.name === "us") {
                child.material = new THREE.MeshBasicMaterial( {
                    color: 0x268dcb,
                    transparent: true,
                    opacity: 0.2,
                })
                child.scale.set(0.5 ,0.5, 0.5);
            }
        })

        const targetGroup = model.getObjectByName(targetName);
        if( targetGroup ) {
            targetGroup.traverse( (child) => {
                if( child.isMesh ) {
                    child.material = new THREE.MeshBasicMaterial( {
                        color: 0xff9743, //0x7cbee7
                    })
                    child.scale.multiplyScalar( 1.5);
                }
            })
        }
    }

    // orbit.control
    const orbit = new OrbitControls( camera, renderer.domElement );
    orbit.enableZoom = false;
    orbit.enablePan = false;
    orbit.enableDamping = true;
    orbit.minPolarAngle = 1.2;
    orbit.maxPolarAngle = 1.2;
    orbit.autoRotate = true;
    orbit.autoRotateSpeed = 3;

    orbit.update();
    
    
    


    const animate = () => {
        
        renderer.render( scene, camera );
        orbit.update();
    }
    
    renderer.setAnimationLoop( animate );


    // RWD
    window.addEventListener( "resize", () => {
        let width = window.innerWidth * 2 / 3 ;
        if(width < 500){
            width = 500;
        }
        camera.aspect = 1 / 1;
        camera.updateProjectionMatrix();
        renderer.setSize( width, width);
    })

}