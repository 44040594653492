import Header from "./components/Header";
import DeliverySec from "./components/DeliverySec";
// import EarthAnim from "./components/EarthAnim";
// import Earth3D from "./components/Earth3D";
// import EarthDot from "./components/EarthDot";
import ArrowBTNs from "./components/ArrowBTNs";
import Footer from "./components/Footer";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import "./Home.css";
import EarthToon from "./components/EarthToon";
import { LocationContext } from "../App";

const Home = () => {
    const navigate = useNavigate();
    const {languageBook} = useContext(LocationContext);
    return(
        <>
            <Helmet>
                <title>聯合資訊 | 各類金融商品服務</title>
                <meta name="description" content="聯合資訊自2018年從電話下單轉型為網路電子下單，提供保障客戶隱私及安全交易的下單平台，並增加更多元的金融商品，推動金融商品與國際接軌。" />
                <meta name="keywords" content="聯合資訊, 期貨平台, 股票平台, 網路下單, 數位化下單"></meta>
                <meta property="og:title" content="聯合資訊 | 各類金融商品服務" />
                <meta property="og:image" content="/pictures/un077-logo-white-light-square.png" />
                <meta property="og:description" content="聯合資訊自2018年從電話下單轉型為網路電子下單，提供保障客戶隱私及安全交易的下單平台，並增加更多元的金融商品，推動金融商品與國際接軌。" />
            </Helmet>
            <Header />
            <EarthToon />
            {/* <EarthDot /> */}
            <div id="earth-wrapper">
                {/* <EarthCanvas /> */}
                {/* <EarthAnim /> */}
                {/* <Earth3D /> */}
                {/* <video autoPlay loop muted playsInline>
                    <source src="/videos/un077-earth_20240531-GP_VP9-H265.mov" type="video/mov; codecs=hevc" />
                    <source src="/videos/un077-earth_20240531-GP_VP9.webm" type="video/webm" />
                </video> */}
            </div>
            <section id="landing-sec" className="reading-sec">
                <h1 id="main-title">{languageBook.siteTitle}</h1>
                <h2>{languageBook.subTitle}</h2>
                <div id="btns-wrapper">
                    <button onClick={() => {
                        navigate("/");
                        navigate("/futures-teaching")
                    }} className="cta">{languageBook.futuresPlatform}</button>
                    <button onClick={() => navigate("/stock-teaching")} className="cta">{languageBook.stockPlatform}</button>
                </div>
            </section>
            <section id="description-sec" className="reading-sec fade-sec">
                <div id="description" className="card slide-in js-scroll-anim">
                    {/* <h3 id="sub-title" className="text-glow">各類金融商品服務</h3> */}
                    <p>{languageBook.homeCompanyDesc}</p>
                    {/* <button id="learn-more-btn">了解更多</button> */}
                </div>
            </section>
            <section id="line-sec" className="reading-sec fade-sec">
                <h4 className="text-center js-scroll-anim">{languageBook.homeLine01}</h4>
                <h2 id="line-title" className="text-center js-scroll-anim">{languageBook.homeLine02}</h2>
                <div id="line-wrapper" className="link-wrapper js-scroll-anim">
                    <p style={{textAlign: "center", paddingBottom: "1rem"}}>{languageBook.homeLine03}</p>
                    <a href="https://line.me/R/ti/p/@030golnu?from=page&accountId=030golnu" target="_blank" rel="noopener noreferrer"  id="line-link"> 
                        <img className="line-logo" src="/pictures/LINE_logo.svg" alt="LINE-logo" />
                        {/* <img className="line-logo" src="/pictures/LINE_logo_white.svg" alt="LINE-logo" />
                        <img className="line-logo line-glow" src="/pictures/LINE_logo_white.svg" alt="LINE-logo" /> */}
                    </a>
                    
                </div>
            </section>
            <section id="count-sec" className="reading-sec fade-sec">
                <div id="settlement-card" className="card slide-in js-scroll-anim">
                    <h2 className="text-center">{languageBook.homeCard01Title}</h2>
                    <p id="settlement-dis" className="text-center">{languageBook.homeCard01Desc}</p>
                </div>                
            </section>
            <section id="cards-sec" className="reading-sec fade-sec">
                <div className="card qa-card slide-in js-scroll-anim">
                    <img className="qa-img" src="/pictures/company-55-2-story-1.png" alt="" />
                    <h3 className="qa-title">{languageBook.homeCard02Title}</h3>
                    <p>{languageBook.homeCard02Desc}</p>
                </div>
                <div className="card qa-card slide-in js-scroll-anim">
                    <img className="qa-img" src="/pictures/company-55-2-story-2.png" alt="" />
                    <h3 className="qa-title">{languageBook.homeCard03Title}</h3>
                    <p>{languageBook.homeCard03Desc}</p>
                </div>
            </section>
            <DeliverySec />
            <section className="reading-sec fade-sec js-scroll-anim">
                <h2 id="cta-bottom-title" className="text-center js-scroll-anim">{languageBook.experienceTitle}</h2>
                <ArrowBTNs />
            </section>
            <Footer />
        </>
    );
}

export default Home;